import Typography from "typography"
import saintAnnesTheme from "typography-theme-st-annes"
saintAnnesTheme.overrideThemeStyles = ({ rhythm}, options) => ({
    img: {
        marginBottom: rhythm(0),
    },
})

const typography = new Typography(saintAnnesTheme)
export const { scale, rhythm, options } = typography
export default typography