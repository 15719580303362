import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import PropTypes from "prop-types"
const ArticleListItem = styled.li`
    list-style:none;
    margin: 0 ${rhythm(1/3)} ${rhythm(2)} ${rhythm(1/3)};
    max-width: 23em;
`
const ArticleTitle = styled.h2`
    margin-top: ${rhythm(1/2)};
    margin-bottom: ${rhythm(1/2)};
`
const ArticleUpdatedDate = styled.p`
    margin-top: ${rhythm(1/2)};
    margin-bottom: ${rhythm(1/2)};
    text-transform: uppercase;
    font-size: 14px;
`
const ArticleLink = ({ post }) => {
    const slug = post.fields.slug
    const { title, dateUpdated, excerptIMG, excerptText, excerptIMGAltText } = post.frontmatter
    return (
        <ArticleListItem>
            <img src={excerptIMG} alt={excerptIMGAltText} />
            <Link to={slug}>
                <ArticleTitle>{title}</ArticleTitle>
            </Link>
            <ArticleUpdatedDate>Updated {dateUpdated}</ArticleUpdatedDate>
            <p>{excerptText}</p>
        </ArticleListItem>
    )
}
ArticleLink.propTypes = {
    post: PropTypes.shape({
        frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            dateUpdated: PropTypes.instanceOf(Date),
            excerptIMG: PropTypes.string.isRequired,
            excerptText: PropTypes.string.isRequired,
            excerptIMGAltText: PropTypes.string.isRequired,
        }),
        fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
        }),
    }),
}
export default ArticleLink