import React from "react"
import ArticleLink from "../components/article-link"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import styled from "@emotion/styled"
const CategoryPageHeaderBar = styled.div`
    display: flex;
    padding: 1.1em 0em 1.1em 0em;
    align-items: baseline;
    max-width: 75em;
    margin: 0 auto;
`
const CategoryPageHeader = styled.h1`
    margin: 0 0.5em 0 0;
    padding: 0;
    text-transform: capitalize;
`
const ArticleCountString = styled.p`
    margin: 0;
    padding: 0;
`
const ArticleList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    max-width: 75rem;    
    margin: 0 auto;
    padding: 2em 0;
`
const ArticleCategoryPage = ({ pageContext, data }) => {
    const { tag } = pageContext
    const { edges, totalCount } = data.allMarkdownRemark
    const ArticleCount = `${totalCount} article${
        totalCount === 1 ? "" : "s"
    }`
    const Articles = edges
    .map(edge => <ArticleLink key={edge.node.id} post={edge.node} />)
    return (
        <Layout>
            <div>
                <CategoryPageHeaderBar>
                    <CategoryPageHeader>{tag}</CategoryPageHeader>
                    <ArticleCountString>{ArticleCount}</ArticleCountString>
                </CategoryPageHeaderBar>
                <ArticleList>
                    {Articles}
                </ArticleList>
                {/* <Link to="/tags">All tags</Link> */}
            </div>
        </Layout>
    )
}
ArticleCategoryPage.propTypes = {
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            totalCount: PropTypes.number.isRequired,
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.node,
                }).isRequired,
            ),
        }),
    }),
}
export default ArticleCategoryPage
export const pageQuery = graphql`
    query($tag: String) {
        allMarkdownRemark(
            limit: 2000
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        dateUpdated(formatString: "MMMM DD, YYYY")
                        excerptIMG
                        excerptText
                        excerptIMGAltText
                    }
                }
            }
        }
    }
`